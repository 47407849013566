import { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'
import sampleImage from './img/sample/orig.jpeg'
import sampleImageA from './img/sample/a.jpeg'
import sampleImageB from './img/sample/b.jpeg'
import sampleImageC from './img/sample/c.jpeg'
import sampleImageD from './img/sample/d.jpeg'


const FileView = ({ onPhotoOrientationChange }) => {
  // const [clutList, setClutList] = useState([])
  const containerRef = useRef()
  const wrapperRef = useRef()
  const firstPreviewWrapperRef = useRef()
  const [file, setFile] = useState()
  const [activeClutIndex, setActiveClutIndex] = useState(-1)
  const [disablePreview, setDisablePreview] = useState(false)

  const setActiveClut = (i) => {
    window.gtag('event', 'pick_clut')
    setActiveClutIndex(i)
  }

  const startDisablePreview = () => {
    setDisablePreview(true)

    window.addEventListener('pointerup', () => {
      setDisablePreview(false)
    }, { once: true })
  }

  const sampleImages = [sampleImageA, sampleImageB, sampleImageC, sampleImageD]

  return (
    <div className="landscape" id="photoview-container">
      <div className="photoview">

        <div ref={containerRef} className={`mainphoto-container loaded`}>

          <div className={`canvas-wrapper`}
            ref={wrapperRef}
          >
            {
              <img
                src={(activeClutIndex > -1 && !disablePreview) ? sampleImages[activeClutIndex] : sampleImage}
                onPointerDown={startDisablePreview}
                onTouchEnd={() => setDisablePreview(false)}
              />
            }
          </div>
        </div>
        <div className="previews">
          {[sampleImageA, sampleImageB, sampleImageC, sampleImageD].map((path, i) => {
            const active = activeClutIndex === i

            return (
              <div
                className={`preview-container preview ${active ? 'active' : ''}`}
                key={i}
                id={path}
              >
                <div className='preview-wrapper' ref={i === 0 ? firstPreviewWrapperRef : null}>
                  <img
                    src={path}
                    onClick={() => setActiveClut(i)}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>)
}



const GLView = forwardRef((
  { photo, clut, inputMeta, makeSquare, initialWidth, initialHeight, ...props }, forwardRef) => {
  const clutData = clut ? clut.data : null
  const meta = clut ? clut.meta : null

  const canvasRef = useRef()
  useImperativeHandle(forwardRef, () => canvasRef.current)

  const wrapperRef = useRef()
  const [physicalSize, setPhysicalSize] = useState([
    initialWidth * window.devicePixelRatio,
    initialHeight * window.devicePixelRatio])
  const programRef = useRef()
  const clutHasLoaded = clutData ? true : false

  const renderWidth = physicalSize[0] / window.devicePixelRatio
  const renderHeight = physicalSize[1] / window.devicePixelRatio

  const lastResizeWidthRef = useRef(0)
  const handleWindowResize = useCallback(() => {

    if (wrapperRef.current) {
      const { clientWidth, clientHeight } = wrapperRef.current

      if (lastResizeWidthRef.current === clientWidth) {
        return
      }

      lastResizeWidthRef.current = clientWidth

      if (makeSquare) {
        const scaleX = clientWidth / photo.width
        // const scaleY = clientHeight / photo.height
        const scale = Math.min(1.0, scaleX)
        const longestEdge = Math.max(photo.width, photo.height)
        setPhysicalSize([longestEdge * scale, longestEdge * scale])
      } else {
        const scaleX = clientWidth / photo.width
        // const scaleY = clientHeight / photo.height
        // const scale = Math.min(1.0, scaleX, scaleY)
        // setPhysicalSize([photo.width * scale, photo.height * scale])

        // setPhysicalSize([clientWidth, photo.height * scaleX])
        setPhysicalSize([photo.width, photo.height])
      }
    }
  }, [photo, makeSquare])

  useEffect(() => {
    handleWindowResize()
  }, [wrapperRef, handleWindowResize])

  useEffect(() => {
    if (canvasRef.current) {
      wrapperRef.current = canvasRef.current.parentElement
      handleWindowResize()
    }
  }, [canvasRef, handleWindowResize])

  useEffect(() => {
    window.addEventListener('resize',
      handleWindowResize)
  }, [handleWindowResize])

  return (
    <canvas
      ref={canvasRef}
      width={physicalSize[0]}
      height={physicalSize[1]}
      {...props}
    />
  )
})


export const InstantProcessDemo = () => {
  return <FileView onPhotoOrientationChange={() => { }} />
}