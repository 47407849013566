import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react'
import axios from 'axios'
import { InstantProcessDemo } from './InstantProcessDemo';
import utils from './utils'


const userStates = {
  anonymous: "anonymous",
  submitted: "submitted",
  registered: "registered",
}

function App() {
  const [email, setEmail] = useState(() => { 
    console.log(utils.readCookie("registeredEmail"))
    return utils.readCookie("registeredEmail")
  })
  const [userState, setUserState] = useState(() => {
    return utils.readCookie("registeredEmail") ? userStates.registered : userStates.anonymous
  })

  // utils.removeCookie("registeredEmail")

  console.log(userState, email)

  const disableSignup = userState != userStates.anonymous
  
  const onSubmit = (e) => {
    e.preventDefault()

    setUserState(userStates.submitted)
    
    const input = document.getElementsByName("email")[0].value
    document.activeElement.blur()

    const formData = new FormData()
    formData.append('email', input)

    axios.post("https://api.protou.ch/betasignup", formData).then(r => {
      console.log(r)

      // if success, set registeredEmail cookie
      if (r.status === 200) {
        setUserState(userStates.registered)
        utils.writeCookie("registeredEmail", input)

        document.getElementById("products").scrollIntoView({
          behavior: 'smooth'
        })
      } else {
        alert(r.text)
      }
    })

    // return false onSubmit to disable page reload
    return false
  }

  return (
    <>
      <div id="container" className={`${disableSignup ? 'submitted' : ''}`}>
        <img id="logo" src="img/logo.svg" />
        {/* <h1 className="heading-text">Improve the mood of any photo instantly</h1> */}
        <h1 className="heading-text">Instantly improve the mood of any photo</h1>
        <InstantProcessDemo />
        <div id="signup-container">
          <h1 className="heading-text">Sign up for beta access</h1>
          {/* <form id="signup" action="https://api.protou.ch/betasignup" method="post" onsubmit="onSubmit"> */}
          <form id="signup" action="#" onSubmit={onSubmit}>
            <input type="email" name="email" defaultValue={email} placeholder="my@mail.com" required />
            <input type="submit" value="Submit" />
          </form>
        </div>
        <div id="products">
          <a className={`product ${userState === userStates.registered ? '' : 'wip'}`} href="https://testflight.apple.com/join/EYEI1PMd" target="_blank" onClick={() => { window.gtag('event', 'click_link_testflight') }}>
            {/* <a className="product wip" target="_blank" onclick="window.gtag('event', 'click_link_testflight')">  */}
            {/* <a className="product" href="https://apps.apple.com/app/id1596450067" target="_blank" onclick="window.gtag('event', 'click_link_camera')">  */}
            {/* <div className="product">  */}
            <img className="app-preview" src="img/camera-preview.jpg" />
            <img className="title-logo" src="img/logo.svg" />
            <span className="title-name">Camera</span>
            <div className="get">
              {/* <img className="wip" src="img/appstore.png" />  */}
              <img src="img/testflight.png" />
            </div>
            {/* </div> */}
          </a>
          <a className={`product ${userState === userStates.registered ? '' : 'wip'}`} href="https://instant.protou.ch/" target="_blank" onClick={() => { window.gtag('event', 'click_link_instant') }}>
            {/* <a className="product wip" target="_blank" onclick="window.gtag('event', 'click_link_instant')"> */}
            <img className="app-preview" src="img/instant-preview.jpg" />
            <img className="title-logo" src="img/logo.svg" />
            <span className="title-name">Instant Process</span>
            <div className="get">
              <img src="img/webapp.png" />
            </div>
          </a>
        </div>
      </div>
      <div id="footer">
        <span className="email"><a href="mailto:carl@protou.ch">carl@protou.ch</a></span><br />
        <span className="copyright">© Carl Öst Wilkens 2021</span>
      </div>
    </>
  );
}

export default App;
