const defaultShaderType = ['VERTEX_SHADER', 'FRAGMENT_SHADER']

const loadShader = (gl, shaderSource, shaderType) => {
  const shader = gl.createShader(shaderType)
  gl.shaderSource(shader, shaderSource)
  gl.compileShader(shader)

  const compiled = gl.getShaderParameter(shader, gl.COMPILE_STATUS)
  if (!compiled) {
    const lastError = gl.getShaderInfoLog(shader)
    console.error(
      "shader error '" +
      shader +
      "':" +
      lastError +
      `\n` +
      shaderSource
        .split('\n')
        .map((l, i) => `${i + 1}: ${l}`)
        .join('\n')
    )
    gl.deleteShader(shader)
    return null
  }

  return shader
}

const createProgram = (gl, shaders, opt_attribs, opt_locations) => {
  const program = gl.createProgram()
  shaders.forEach(function (shader) {
    gl.attachShader(program, shader)
  })
  if (opt_attribs) {
    opt_attribs.forEach(function (attrib, ndx) {
      gl.bindAttribLocation(
        program,
        opt_locations ? opt_locations[ndx] : ndx,
        attrib
      )
    })
  }
  gl.linkProgram(program)

  const linked = gl.getProgramParameter(program, gl.LINK_STATUS)
  if (!linked) {
    const lastError = gl.getProgramInfoLog(program)
    console.error('Error in program linking:' + lastError)
    gl.deleteProgram(program)
    return null
  }
  return program
}

const createProgramFromSources = (
  gl,
  shaderSources,
  opt_attribs,
  opt_locations
) => {
  const shaders = []
  for (let i = 0; i < shaderSources.length; ++i) {
    shaders.push(loadShader(gl, shaderSources[i], gl[defaultShaderType[i]]))
  }
  return createProgram(gl, shaders, opt_attribs, opt_locations)
}

const dataURLToBlob = dataURL => {
  const BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts1 = dataURL.split(',')
    const contentType = parts1[0].split(':')[1]
    const raw = parts1[1]

    return new Blob([raw], { type: contentType })
  }

  const parts2 = dataURL.split(BASE64_MARKER)
  const contentType = parts2[0].split(':')[1]
  const raw = window.atob(parts2[1])
  const rawLength = raw.length
  const uInt8Array = new Uint8Array(rawLength)

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

const limitLongestEdge = (image, maxLongestEdge) => {
  const canvas = document.createElement('canvas')
  const longestEdge = Math.max(image.width, image.height)
  const scale = maxLongestEdge / longestEdge

  canvas.width = scale < 1.0 ? scale * image.width : image.width
  canvas.height = scale < 1.0 ? scale * image.height : image.height

  const ctx = canvas.getContext('2d')
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

  return canvas.toDataURL('image/jpeg', 1.0)
}

const dataURLAsImage = (dataURL) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = dataURL
    image.onload = () => {
      resolve(image)
    }
    image.onerror = (err) => {
      reject(err)
    }
  })
}

const fileAsDataURL = (file) => {
  return new Promise(resolve => {
    var reader = new FileReader()
    reader.onload = () => {
      const dataURL = reader.result
      resolve(dataURL)
    }
    reader.readAsDataURL(file)
  })
}

const stripExifFromFile = (file) => {
  return new Promise(resolve => {
    const fr = new FileReader();
    fr.onload = e => {
      const result = removeExif(file, e.target.result);
      resolve(result)
    };
    fr.readAsArrayBuffer(file);
  })
}

// https://stackoverflow.com/questions/27638402/strip-exif-data-from-image
const removeExif = (orig, res) => {
  return new Promise(resolve => {
    const dv = new DataView(res);
    let offset = 0;
    let recess = 0;
    const pieces = [];
    let i = 0;

    if (dv.getUint16(offset) === 0xffd8) {
      offset += 2;
      let app1 = dv.getUint16(offset);
      offset += 2;

      while (offset < dv.byteLength) {
        if (app1 === 0xffe1) {
          pieces[i] = { recess, offset: offset - 2 };
          recess = offset + dv.getUint16(offset);
          i++;
        }
        else if (app1 === 0xffda) {
          break;
        }
        offset += dv.getUint16(offset);
        app1 = dv.getUint16(offset);
        offset += 2;
      }

      if (pieces.length > 0) {
        var newPieces = [];
        pieces.forEach(function (v) {
          newPieces.push(res.slice(v.recess, v.offset));
        });
        newPieces.push(res.slice(recess));
        var br = new Blob(newPieces, { type: 'image/jpeg' });

        resolve(br)
        return
      }
    }

    resolve(orig)
  })
}


const writeCookie = (name, value) => {
  document.cookie = `${name}=${value}; Max-Age=86000`;
}

const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const removeCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}


const utils = {
  createProgramFromSources,
  dataURLToBlob,
  limitLongestEdge,
  dataURLAsImage,
  fileAsDataURL,
  stripExifFromFile,
  writeCookie,
  readCookie,
  removeCookie
}

export default utils
